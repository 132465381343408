import React from 'react';
import './Footer.css';

export function Footer() {
	const year = new Date().getFullYear();

	return (
		<footer className="p-4">
			<div className="container d-flex justify-content-center">
				<div className="text w-100">&#169;{year} Emil Olovsson AB. All Rights Reserved.</div>
				<div className="w-100">
					<div className="text text-center">Emil Olovsson AB</div>
					<div className="text text-center">Org. Nr. 559444-5297</div>
					<div className="text text-center">Swedish f-tax certified</div>
				</div>
				<div className="text w-100">
					<div className="text-end">
						<a className="text" href="mailto:Emil.Olovsson@gmail.com">Emil.Olovsson@gmail.com</a>
					</div>
					<div className="text text-end d-flex justify-content-end">
						<a
							className="s-icon icon_linkedin"
							href="https://www.linkedin.com/in/emil-olovsson-408344a6/"
							target="_blank"
							rel="noreferrer"
						></a>
					</div>
				</div>
			</div>
		</footer>
	);
}
