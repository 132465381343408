import React from 'react';
import { useRef } from 'react';
import { useOnScreen } from '../../hooks/useOnScreen.js';
import './Skill.css';

// eslint-disable-next-line react/prop-types
export function Skill({name, level}) {
	const elementRef = useRef(null);
	const classOnScreen = useOnScreen(elementRef);

	const maxLevel = 5;

	return (
		<div ref={elementRef} className={`${classOnScreen} p-0`}>
			<div className="d-flex align-items-center">
				{[...Array(maxLevel)].map((x, i) => {
					let css = "level_circle";
					if (i < level)
						css += " checked";
					return (
						<span key={i} className={css}></span>
					);
				})}
				<span className="text">{name}</span>
			</div>
		</div>
	);
}
