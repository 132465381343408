import React, { Component } from 'react';
import { NavMenu } from './navmenu/NavMenu';
import { CanvasStar } from './CanvasStar';
import { Footer } from './footer/Footer';
import './View.css';

export class View extends Component {
	static displayName = View.name;

	render() {
		return (
			<>
				<NavMenu />
				<div id="main_container" className="container">
					{/* eslint-disable-next-line react/prop-types */}
					{this.props.children}
				</div>
				<div id="top_transition"></div>
				<CanvasStar />
				<Footer />
			</>
		);
	}
}
