import React from 'react';
import { Home } from "./view/home/Home";
import { Expertise } from "./view/expertise/Expertise";
import { Assignments } from "./view/assignments/Assignments";
import { About } from "./view/about/About";
import { Contact } from "./view/contact/Contact";

const AppRoutes = [
	{
		index: true,
		element: <Home />
	},
	{
		path: '/expertise',
		element: <Expertise />
	},
	{
		path: '/projects',
		element: <Assignments />
	},
	{
		path: '/about',
		element: <About />
	},
	{
		path: '/contact',
		element: <Contact />
	}
];

export default AppRoutes;
