import React from 'react';
import { useRef, useState, useEffect } from 'react';
import { useOnScreen } from '../../hooks/useOnScreen';
import './ExpertisePart.css';

interface expertiseItem {
	header: {
		title?: string,
		gradient?: boolean,
		extrapaddingtop?: boolean,
		descriptions: []
	},
	services?: {
		descriptions: []
	}
}

export function ExpertisePart({ expertise }: { expertise: expertiseItem }) {
	const elementRef = useRef(null);
	const classOnScreen = useOnScreen(elementRef);
	const [size, setSize] = useState(getSize());

	function getSize() {
		return [
			(document.documentElement.clientWidth || document.body.clientWidth),
			(document.documentElement.clientHeight || document.body.clientHeight)
		];
	}

	useEffect(() => {
		const updateSize = () => {
			setSize(getSize());
		}

		window.addEventListener('resize', updateSize);
		return () => {
			window.removeEventListener('resize', updateSize);
		};
	}, [size])

	let headerClassName;
	if (!expertise.header.gradient)
		headerClassName = 'header_small header_simple';
	else
		headerClassName = 'header';

	const flexColumn = size[0] < 810;

	return (
		<div ref={elementRef} className={`${classOnScreen} d-flex ${flexColumn ? 'flex-column' : 'flex-row'} ${expertise.header.extrapaddingtop !== false ? 'pt-4' : ''}`}>
			<div className={`header_container_expertise ${expertise.services ? 'me-4' : ''}`}>
				{expertise.header.title &&
					<div className={headerClassName}>{expertise.header.title}</div>
				}
				<div className={`${expertise.header.title ? 'pt-3' : ''}`}>
					{expertise.header.descriptions.map((description, index) => {
						return (
							<div
								key={index}
								className="text"
							>
								{description}
							</div>
						);
					})}
				</div>
			</div>
			{expertise.services && 
				<div className={`services_container_expertise ${!flexColumn ? 'ms-auto' : 'pt-3'}`}>
					<div className="header_smallest header_simple">Services</div>
					{expertise.services.descriptions.map((description, index) => {
						return (
							<div
								key={index}
								className="text text_small_expertise text-nowrap"
							>
								{description}
							</div>
						);
					})}
				</div>
			}
		</div>
	);
}
