import React from 'react';
import { useRef } from 'react';
import { useOnScreen } from '../../hooks/useOnScreen';

export function Contact() {
	const elementRef = useRef(null);
	const classOnScreen = useOnScreen(elementRef);

	return (
		<div ref={elementRef} className={`${classOnScreen}`}>
			<div className="header">Contact</div>
			<div className="py-4">
				<div>
					<a className="text d-inline-flex align-items-center" href="mailto:Emil.Olovsson@gmail.com">
						<span className="icon icon_email_50_50 me-3"></span>
						Emil.Olovsson@gmail.com
					</a>
				</div>
				<div>
					<a
						className="text d-inline-flex align-items-center"
						href="https://www.linkedin.com/in/emil-olovsson-408344a6/"
						target="_blank"
						rel="noreferrer"
					>
						<span className="icon icon_linkedin me-3"></span>
						LinkedIn
					</a>
				</div>
			</div>
			<div className="header_small header_simple">Limited Liability Company</div>
			<div className="pt-4">
				<div className="text">Emil Olovsson AB</div>
				<div className="text">Org. Nr. 559444-5297</div>
				<div className="text">Swedish f-tax certified</div>
			</div>
		</div>
	);
}
