import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export function NavMenu() {
	const ref = useRef(null);
	const hamburgerRef = useRef(null);
	const [blur, setBlur] = useState(0);
	const [scrollY, setScrollY] = useState(0);
	const [collapsed, setCollapsed] = useState(true);
	const maxScrollY = 32;

	function toggleNavbar() {
		setCollapsed(collapsed => (!collapsed));
	}

	useEffect(() => {
		const handleClickOutsideHeader = (event) => {
			if (event.target && !ref.current.contains(event.target))
				toggleNavbar();
		};

		const handleWindowScroll = () => {
			let currScrollY = window.scrollY;

			if (!collapsed && hamburgerRef.current.offsetHeight)
				currScrollY = maxScrollY;

			if (currScrollY === 0) {
				setScrollY(0);
				setBlur(0);
				return;
			}

			if (currScrollY > maxScrollY)
				currScrollY = maxScrollY;

			setScrollY(currScrollY);
			setBlur(Math.min(scrollY, 3));
		};

		window.addEventListener('scroll', handleWindowScroll, false);
		window.addEventListener('resize', handleWindowScroll, false);
		if (!collapsed) {
			window.addEventListener('mousedown', handleClickOutsideHeader, false);
			window.addEventListener('scroll', handleClickOutsideHeader, false);
		}
		handleWindowScroll();
		return () => {
			window.removeEventListener('scroll', handleWindowScroll, false);
			window.removeEventListener('resize', handleWindowScroll, false);
			window.removeEventListener('mousedown', handleClickOutsideHeader, false);
			window.removeEventListener('scroll', handleClickOutsideHeader, false);
		};
	}, [collapsed, hamburgerRef, scrollY, blur]);

	return (
		<header ref={ref} className="sticky-top">
			<div style={{ position: 'absolute', width: '100%' }}>
				<div
					style={{
						backgroundColor: 'rgba(4, 6, 21, ' + (0.5 * (scrollY / maxScrollY)) + ')',
						backdropFilter: `blur(${blur}px)`
					}}
				>
					<Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white py-0" container light>
						<NavbarBrand className="text-white p-3" tag={Link} to="/">Emil Olovsson</NavbarBrand>
						<NavbarToggler onClick={toggleNavbar} className="mr-2 navbar-dark border-0 shadow-none">
							<span ref={hamburgerRef} className="icon icon_hamburger_64_64"></span>
						</NavbarToggler>
						<Collapse className="d-sm-inline-flex" isOpen={!collapsed} navbar style={{transition: 'none'}}>
							<ul className="navbar-nav w-100 justify-content-center">
								<NavItem>
									<NavLink tag={Link} className="text-white p-3" to="/expertise" onClick={() => { setCollapsed(true) }}>Expertise</NavLink>
								</NavItem>
								<NavItem>
									<NavLink tag={Link} className="text-white p-3" to="/projects" onClick={() => { setCollapsed(true) }}>Projects</NavLink>
								</NavItem>
								<NavItem>
									<NavLink tag={Link} className="text-white p-3" to="/about" onClick={() => { setCollapsed(true) }}>About</NavLink>
								</NavItem>
							</ul>
							<ul className="navbar-nav">
								<NavItem className="ml-1">
									<NavLink tag={Link} className="rev_main_background_color rounded rev_main_color text-center mx-3" to="/contact" onClick={() => { setCollapsed(true) }}>Contact</NavLink>
								</NavItem>
							</ul>
						</Collapse>
					</Navbar>
				</div>
			</div>
		</header>
	);
}
