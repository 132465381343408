import React from 'react';
import { Assignment } from './Assignment.tsx';

export function Assignments() {

	return (
		<>
			<Assignment
				assignment={{
					prequel: 'Here, we present a sampling of Emil\'s exemplary projects. They serve as a testament to his commitment to excellence. Should you require such standards in your projects, Emil is readily available to assist you. Contact him today for further information.',
					header: 'Portfolio Website',
					mousey: 1,
					descriptions: [
						"This website is self hosted.",
						"",
						"<div class=\"header_smallest header_simple\">Programming languages</div>",
						"JavaScript, HTML5 & CSS.",
						"",
						"<div class=\"header_smallest header_simple\">Skills</div>",
						"Windows & Linux, React, TypeScript, npm, ESLint, Bootstrap, GitHub, GitHub Actions (CI/CD), GitHub API, LibSodium, ASP.NET, Certificates (PEM, PFX), Cloudflare, DDClient, Docker & Docker Compose, Port Forwarding, IP-configuration, Firewall & SSH."
					]
				}}
			/>
			<Assignment
				assignment={{
					header: 'AutoLedger',
					descriptions: [
						"AutoLedger (<a href='https://get.autoledger.co' target='_blank' class='text'>AutoLedger.co</a>) is an innovative web-based driving ledger. Specifically designed to meet the requirements of the Swedish Tax Agency (Skatteverket), AutoLedger helps users easily and efficiently log their vehicle trips. By integrating modern technology, AutoLedger automates the process of logging trips while also offering the option to manually record trips for older vehicles. AutoLedger demonstrates the ability to transition from concept to technical solutions that meet specific regulatory requirements while providing a user-friendly tool."
					]
				}}
			/>
			<Assignment
				assignment={{
					header: 'Electronic Shelf Labels (ESL)',
					descriptions: [
						"ESL is a platform developed to support multiple electronic shelf label (ESL) providers. It is designed to allow users to link their product range to specific ESL devices and easily manage the appearance of these labels. The platform's flexible structure enables users to choose from multiple ESL providers."
					]
				}}
			/>
			<Assignment
				assignment={{
					header: 'Windows Client',
					descriptions: [
						"Windows Client is a versatile C# application. Users can log into a secure platform and manage their business operations smoothly. Windows Client can be used as a workstation or as a cash register, providing users with the ability to perform various transactions easily and securely. Windows Client is used by hundreds of companies."
					]
				}}
			/>
			<Assignment
				assignment={{
					header: 'Point of Sale (POS)',
					descriptions: [
						"The Point of Sale system is a comprehensive system used by several hundred companies. It offers easy management for users and enables smooth transaction handling and troubleshooting. The system is robust and autonomous, with automated restart in case of any errors and clear API endpoints for data management. It has received overwhelmingly positive feedback thanks to its reliability and ease of use."
					]
				}}
			/>
			<Assignment
				assignment={{
					header: 'Android App',
					descriptions: [
						"Users wanted to be able to conduct business transactions seamlessly from their mobile devices, including inventory management and creating orders and purchases. The app offers a user-friendly interface optimized for touchscreens. It also supports Bluetooth connectivity of barcode scanners for easier inventory management and order processing. Additionally, the app provides full access to all internal system functionalities."
					]
				}}
			/>
			<Assignment
				assignment={{
					header: 'Case Management System (CMS)',
					descriptions: [
						"CMS optimizes the process of managing cases by providing an intuitive and customizable interface that enables quick and smooth collection of case data. Emil led the development of the system, where close collaboration with all departments ensured that the features were designed to fit the organization's needs. The system offers a range of useful features, including relationship management and version control, increasing efficiency and productivity. Security has been prioritized to protect against potential threats. Additional modules have been created to further enhance communication and case management for users."
					]
				}}
			/>
			<Assignment
				assignment={{
					header: 'Navigation',
					descriptions: [
						"Navigation is a new primary landing page for a business system. The challenge in this project was to identify and integrate key features from the old system, which integrated all modules and components into a massive project. Some of the primary features of Navigation include URL management, print handling, integration with multiple third-party APIs, support for multiple interfaces, and the use of Manticore Search for efficient database searches."
					]
				}}
			/>
			<Assignment
				assignment={{
					header: 'Internal Work System',
					descriptions: [
						"Internal Work System is a central system designed to meet the needs of various departments, such as support, quality & testing, operations, development, implementation, project management, finance, and marketing. It ensures effective coordination and management of tasks and resources across the organization."
					]
				}}
			/>
			<Assignment
				assignment={{
					header: 'Translation Tool',
					descriptions: [
						"Led the development of the Translation Tool project enabling multilingual support for an internal system. Implemented the latest technology in machine translation and language processing to streamline the workflow. The tool's flexibility allows for the integration of new translations, and users can easily contribute to and compare different translations across languages."
					]
				}}
			/>
			<Assignment
				assignment={{
					className: '',
					header: 'mPOS - Touch Interface Checkout',
					descriptions: [
						"The mPOS project focused on developing a specialized touch-interface checkout system. Emil crafted a robust foundation that standardized coding practices, implemented efficient design patterns, and elevated the overall quality of newly developed modules and components for touch devices. mPOS has made a significant impact, being adopted by prominent entities. This widespread adoption accentuates the system's effectiveness and reliability in catering to the unique needs of touch-based interfaces, making it a preferred choice for several large companies."
					]
				}}
			/>
		</>
	);
}
