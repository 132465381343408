import * as React from 'react';
import { useRef } from 'react';
import { useOnScreen } from '../../hooks/useOnScreen';
import { GalleryPictures } from '../../components/gallery/Gallery';
import { Mousey } from '../../components/mousey/Mousey';

interface assignment {
	prequel?: string,
	className: string,
	header?: string,
	headerClassName?: string,
	subheader?: string,
	subheaderClassName?: string,
	mousey?: number,
	images?: [],
	descriptions: []
}

export function Assignment({ assignment }: { assignment: assignment }) {
	const elementRef = useRef(null);
	const classOnScreen = useOnScreen(elementRef);

	if (typeof assignment.className !== 'string')
		assignment.className = 'fullpage_container';

	return (
		<div ref={elementRef} className={`${assignment.className} ${classOnScreen}`}>
			{assignment.prequel &&
				<div className="text pb-4">{assignment.prequel}</div>
			}
			<div className="pb-3">
				<div className={`header ${assignment.headerClassName || ''}`}>{assignment.header}</div>
				<br />
				{assignment.subheader &&
					<div className={`header ${assignment.subheaderClassName || ''}`}>{assignment.subheader}</div>
				}
			</div>
			{assignment.images &&
				<GalleryPictures
					images={assignment.images}
				/>
			}
			{assignment.descriptions.map((description, index) => {
				if (!description)
					return <br key={index} />;
				return <div className="text" key={index} dangerouslySetInnerHTML={{ __html: description }}></div>;
			})}
			{assignment.mousey && <Mousey />}
		</div>
	);
}
