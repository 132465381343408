interface portfolio {
	yearsExperience: number
}

export const Portfolio = {
	yearsExperience: Math.round(
		(
			new Date().getTime() - new Date('2015-06-01 00:00:00').getTime() // Microsekunder
		) / 1000 // Sekunder
		/ 60 // Minuter
		/ 60 // Timmar
		/ 24 // Dagar
		/ 365.25 // år
	)
} as portfolio;

interface tools {
	numberToWord: Function
}

export const Tools = {
	numberToWord: (number: number) => {
		switch (number) {
			case 0:
				return 'zero';
			case 1:
				return 'one';
			case 2:
				return 'two';
			case 3:
				return 'three';
			case 4:
				return 'four';
			case 5:
				return 'five';
			case 6:
				return 'six';
			case 7:
				return 'seven';
			case 8:
				return 'eight';
			case 9:
				return 'nine';
			case 10:
				return 'ten';
			case 11:
				return 'eleven';
			case 12:
				return 'twelve';
			case 13:
				return 'thirteen';
			case 14:
				return 'fourteen';
			case 15:
				return 'fifteen';
			case 16:
				return 'sixteen';
			case 17:
				return 'seventeen';
			case 18:
				return 'eighteen';
			case 19:
				return 'nineteen';
			case 20:
				return 'twenty';
			default:
				return 'not a number';
		}
	}
} as tools;