import React, { MouseEventHandler, MutableRefObject } from 'react';
import { useRef, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useOnScreen } from '../../hooks/useOnScreen';
import { Portfolio, Tools } from '../singleton/constants.ts';
import './Gallery.css';

interface pictureItem {
	imageWidth?: number,
	imageHeight?: number,
	imageSrc: string,
	description: string
}

export function GalleryPictures({ images, highlight=false }: { images: pictureItem[], highlight?: boolean }) {
	const elementRef = useRef(null);
	const classOnScreen = useOnScreen(elementRef);
	const [size, setSize] = useState(getSize());
	const [imageIndex, setPrivateImageIndex] = useState(0);
	const [prevImageIndex, setPrevImageIndex] = useState(0);
	const [nextImagePercentage, setNextImagePercentage] = useState(0);
	const timeNextImage = 1000 * 7;
	const [imagesAreLoaded, setImagesAreLoaded] = useState(new Map());
	const [isPaused, setIsPaused] = useState(false);

	const setImageIndex = useCallback((index: number) => {
		setPrevImageIndex(imageIndex);
		setPrivateImageIndex(index);
		setNextImagePercentage(0);
	}, [imageIndex]);

	function getSize() {
		let width = document.documentElement.clientWidth || document.body.clientWidth;
		let height = document.documentElement.clientHeight || document.body.clientHeight;
		const navBarHeight = 55;
		if (width > 1023)
			width = 1023;
		if (height > 575)
			height = 575;
		height -= navBarHeight;
		return [width, height];
	}

	function stopScrolling(e: TouchEvent) {
		if (e.cancelable)
			e.preventDefault();
	}

	useEffect(() => {
		const updateSize = () => {
			setSize(getSize());
		};
		let intervalId: ReturnType<typeof setTimeout>;

		if (images.length > 1) {
			intervalId = setInterval(() => {
				if (
					// This component is not visible
					classOnScreen === 'fade-in-section' ||
					// Is paused
					isPaused ||
					// Image is not loaded
					!imagesAreLoaded.get(imageIndex)
				) {
					clearInterval(intervalId);
					return;
				}
				const percentage = nextImagePercentage + 1;
				setNextImagePercentage(percentage);
				if (percentage === 1000) {
					if (imageIndex === images.length - 1)
						setImageIndex(0);
					else
						setImageIndex(imageIndex + 1);
				}
			}, timeNextImage / 1000);
		}

		window.addEventListener('resize', updateSize);
		return () => {
			window.removeEventListener('resize', updateSize);
			window.document.body.removeEventListener('touchmove', stopScrolling);
			clearInterval(intervalId);
		};
	}, [nextImagePercentage, imageIndex, timeNextImage, classOnScreen, setImageIndex, images.length, imagesAreLoaded, isPaused])

	return (
		<div ref={elementRef} className={`${classOnScreen} p-0`}>
			<div className="d-flex flex-row justify-content-center">
				<div style={{minWidth: 0}}>
					<div className={`m-auto rounded overflow-hidden${highlight || !imagesAreLoaded.get(imageIndex)? ' gallery_highlight' : ''}`}>
						{
							images.length > 1 &&
							<>
								<div
									className="next-slide-timer position-absolute bottom-0"
									style={{
										width: nextImagePercentage / 10 + '%'
									}}
								>
								</div>
								<div className="next-slide-radiocontainer position-absolute bottom-0 d-flex flex-row justify-content-center w-100">
									{images.map((_img: pictureItem, index: number) => {
										return (
											<input
												className="form-check-input my-0 mx-2"
												type='radio'
												key={index}
												checked={imageIndex === index}
												onChange={() => setImageIndex(index)}
												onClick={() => setImageIndex(index)}
											></input>
										);
									})}
								</div>
							</>
						}
						{images.map((img: pictureItem, index: number) => {
							return (
								<Image
									key={index}
									onClick={() => {
										setIsPaused(isPaused => !(isPaused));
									}}
									onLoad={() => {
										imagesAreLoaded.set(index, 1);
										setImagesAreLoaded(new Map(imagesAreLoaded));
									}}
									onSwipeLeft={() => {
										if (imageIndex === images.length - 1)
											setImageIndex(0);
										else
											setImageIndex(imageIndex + 1);
									}}
									onSwipeRight={() => {
										if (imageIndex === 0)
											setImageIndex(images.length - 1);
										else
											setImageIndex(imageIndex - 1);
									}}
									onSwipeStartHorizontal={() => {
										window.document.body.style.overflow = 'hidden';
										window.document.body.addEventListener('touchmove', stopScrolling, false);
									}}
									onSwipeEnd={() => {
										window.document.body.style.overflow = '';
										window.document.body.removeEventListener('touchmove', stopScrolling);
									}}
									imageWidth={img.imageWidth || size[0]}
									imageHeight={img.imageHeight || size[1]}
									isImageVisible={index === imageIndex}
									image={{
										className: "",
										alt: "images",
										style: {
											maxWidth: size[0],
											maxHeight: size[1],
											minWidth: 0,
											pointerEvents: index !== imageIndex ? 'none' : 'auto',
											left: index !== prevImageIndex && index !== imageIndex ? 99999 : 0,
											top: 0
										},
										src: img.imageSrc
									}}
								></Image>
							);
						})}
					</div>
					{
						images.length !== 0 && images[imageIndex].description &&
						<div className="text image_text_description">{images[imageIndex].description}</div>
					}
				</div>
			</div>
		</div>
	);
}

function Spinner({ itemWidth, itemHeight, maxHeight }: { itemWidth: number, itemHeight: number, maxHeight: number }) {
	return (
		<div
			className="d-flex justify-content-center align-items-center"
			style={{
				width: itemWidth,
				height: itemHeight,
				maxWidth: maxHeight * (itemWidth / itemHeight),
				maxHeight: maxHeight
			}}
		>
			<div className="spinner-border text" role="status">
			</div>
		</div>
	)
}

interface imageItem {
	className: string,
	alt: string,
	style: {
		maxWidth: number,
		maxHeight: number,
		minWidth: number,
		pointerEvents: 'none' | 'auto',
		left: number,
		top: number
	},
	src: string
}

function Image({
	imageWidth,
	imageHeight,
	isImageVisible,
	onLoad,
	image,
	onClick,
	onSwipeLeft,
	onSwipeRight,
	onSwipeStartHorizontal,
	onSwipeEnd
}: {
	imageWidth: number,
	imageHeight: number,
	isImageVisible: boolean,
	onLoad: Function,
	image: imageItem,
	onClick?: MouseEventHandler,
	onSwipeLeft?: EventListenerOrEventListenerObject,
	onSwipeRight?: EventListenerOrEventListenerObject,
	onSwipeStartHorizontal?: EventListenerOrEventListenerObject,
	onSwipeEnd?: EventListenerOrEventListenerObject
}) {
	const [isLoading, setIsLoading] = useState(true);
	const imageRef = useRef() as MutableRefObject<HTMLImageElement>;

	let classNameImage = 'top-0 start-0';
	if (isImageVisible && !isLoading)
		classNameImage += ' fade-in-image position-relative';
	else
		classNameImage += ' fade-out-image position-absolute';

	// Safari bug
	if (image.style.maxWidth && imageWidth < image.style.maxWidth)
		image.style.maxWidth = imageWidth;

	if (image.style.maxHeight && imageHeight < image.style.maxHeight)
		image.style.maxHeight = imageHeight;

	useEffect(() => {
		const imageEl = imageRef.current;
		if (typeof onSwipeLeft === 'function')
			imageEl.addEventListener('swiped-left', onSwipeLeft);
		if (typeof onSwipeRight === 'function')
			imageEl.addEventListener('swiped-right', onSwipeRight);
		if (typeof onSwipeStartHorizontal === 'function')
			imageEl.addEventListener('swipe-start-horizontal', onSwipeStartHorizontal);
		if (typeof onSwipeEnd === 'function')
			imageEl.addEventListener('swipe-end', onSwipeEnd);
		return () => {
			if (typeof onSwipeLeft === 'function')
				imageEl.removeEventListener('resize', onSwipeLeft);
			if (typeof onSwipeRight === 'function')
				imageEl.removeEventListener('resize', onSwipeRight);
			if (typeof onSwipeStartHorizontal === 'function')
				imageEl.removeEventListener('swipe-start-horizontal', onSwipeStartHorizontal);
			if (typeof onSwipeEnd === 'function')
				imageEl.removeEventListener('swipe-end', onSwipeEnd);
		};
	}, [imageRef, onSwipeLeft, onSwipeRight, onSwipeStartHorizontal, onSwipeEnd])

	return (
		<>
			{isLoading && isImageVisible &&
				<Spinner
					itemWidth={imageWidth}
					itemHeight={imageHeight}
					maxHeight={image.style.maxHeight}
				/>
			}
			<div className={`${classNameImage} pe-none d-flex`}>
				<img
					ref={imageRef}
					className={image.className}
					onLoad={() => {
						onLoad();
						setIsLoading(false);
					}}
					onClick={onClick}
					alt={image.alt}
					style={image.style}
					src={image.src}
				></img>
			</div>
		</>
	);
}

export function GalleryVideo() {
	const elementRef = useRef(null);
	const classOnScreen = useOnScreen(elementRef);
	const [size, setSize] = useState(getSize());
	const [isLoading, setIsLoading] = useState(true);
	const video = require('./videos/meet_emil.mp4');
	const videoRef = useRef() as MutableRefObject<HTMLVideoElement>;

	function getSize() {
		let width = document.documentElement.clientWidth || document.body.clientWidth;
		let height = document.documentElement.clientHeight || document.body.clientHeight;
		const navBarHeight = 55;
		if (width > 1023)
			width = 1023;
		if (height > 575)
			height = 575;
		height -= navBarHeight;
		return [width, height];
	}

	useEffect(() => {
		const updateSize = () => {
			setSize(getSize());
		};

		videoRef.current[classOnScreen === 'fade-in-section is-visible' ? 'play' : 'pause']();

		window.addEventListener('resize', updateSize);
		return () => {
			window.removeEventListener('resize', updateSize);
		};
	}, [videoRef, classOnScreen])

	return (
		<div ref={elementRef} className={`${classOnScreen}`}>
			<div className="d-flex flex-row justify-content-center">
				{isLoading &&
					<Spinner
						itemWidth={size[0]}
						itemHeight={size[1]}
						maxHeight={size[1]}
					/>
				}
				<video
					ref={videoRef}
					muted
					playsInline
					onLoadedData={() => { setIsLoading(false) }}
					style={{
						maxHeight: size[1],
						maxWidth: size[0],
						minWidth: 0,
						width: isLoading ? 0 : '',
						height: isLoading ? 0 : '',
						borderRadius: '24px'
					}}
				>
					<source src={video} type="video/mp4" />
				</video>
			</div>
		</div>
	);
}

export function GalleryPrefixText() {
	const elementRef = useRef(null);
	const classOnScreen = useOnScreen(elementRef);
	
	return (
		<div ref={elementRef} className={`${classOnScreen}`}>
			<div className="py-4 header">Meet the Curator</div>
		</div>
	);
}

export function GallerySuffixText() {
	const elementRef = useRef(null);
	const classOnScreen = useOnScreen(elementRef);
	
	return (
		<div ref={elementRef} className={`${classOnScreen}`}>
			<div className="py-4 text">Emil Olovsson is a software developer with over {Tools.numberToWord(Portfolio.yearsExperience)} years of professional experience, and has a strong background in web-based e-commerce full stack development and POS-systems.</div>
			<Link className="text" to="/about">Read More &#62;</Link>
		</div>
	);
}
