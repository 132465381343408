import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useOnScreen } from '../../hooks/useOnScreen';
import { GalleryPictures } from '../../components/gallery/Gallery';
import { Portfolio, Tools } from '../../components/singleton/constants.ts';
import { Skill } from './Skill';
import { Link } from 'react-router-dom';

export function About() {
	const elementRef = useRef(null);
	const classOnScreen = useOnScreen(elementRef);
	const [size, setSize] = useState(getSize());

	// set up the stars
	const skills = useMemo(() => {
		return [
			{ name: 'Angular', level: 5 },
			{ name: 'Bash', level: 5 },
			{ name: 'CSS | SCSS | HTML', level: 5 },
			{ name: 'JavaScript', level: 5 },
			{ name: 'On-premises', level: 5 },
			{ name: 'Docker', level: 5 },
			{ name: 'Java 21 | Java EE 10 | Kotlin', level: 5 },
			{ name: 'JAX-RS', level: 5 },
			{ name: 'Rest API', level: 5 },
			{ name: 'Windows | Linux', level: 5 },
			{ name: 'PHP', level: 5 },
			{ name: 'SQL | T-SQL | JPQL', level: 5 },
			{ name: 'CI/CD', level: 5 },
			{ name: 'Daemon Services', level: 5 },
			{ name: 'Email Management', level: 5 },
			{ name: 'CSV | Excel | PDF', level: 5 },
			{ name: 'POS', level: 5 },
			{ name: 'Version Management', level: 5 },
			{ name: 'API Integration', level: 5 },
			{ name: 'ExtJS', level: 5 },
			{ name: 'Certificates', level: 5 },
			{ name: 'SSH', level: 5 },
			{ name: 'Ethernet | Firewall', level: 5 },
			{ name: 'Manticore Search', level: 5 },
			{ name: 'Web Security', level: 5 },
			{ name: 'GitLab | GitHub | SVN', level: 5 },
			{ name: 'AG Grid', level: 5 },
			{ name: 'Micro Services', level: 5 },
			{ name: 'ETL', level: 5 },

			{ name: 'AWS | Azure', level: 4 },
			{ name: 'Grafana', level: 4 },
			{ name: 'Loki', level: 4 },
			{ name: 'Prometheus', level: 4 },
			{ name: 'Promtail', level: 4 },
			{ name: 'Kubernetes', level: 4 },
			{ name: 'Glassfish', level: 4 },
			{ name: 'Jboss', level: 4 },
			{ name: 'JPA', level: 4 },
			{ name: 'JMS/MQ', level: 4 },
			{ name: 'JSP', level: 4 },
			{ name: 'Kafka', level: 4 },
			{ name: 'Spring Boot', level: 4 },
			{ name: 'JUnit', level: 4 },
			{ name: 'TypeScript', level: 4 },
			{ name: 'React | React Native', level: 4 },
			{ name: 'ESLint', level: 4 },
			{ name: 'Node.js', level: 4 },
			{ name: 'C#', level: 4 },
			{ name: 'JQuery', level: 4 },
			{ name: 'Jira', level: 4 },
			{ name: 'Optical Character Recogition (OCR)', level: 4 },
			{ name: 'Invoicing/Billing', level: 4 },
			{ name: 'ESC/POS', level: 4 },
			{ name: 'Gradle', level: 4 },
			{ name: '2FA', level: 4 },
			{ name: 'WebSocket', level: 4 },
			{ name: 'BankID', level: 4 },
			{ name: 'Canvas', level: 4 },
			{ name: 'Chrome Extension', level: 4 },
			{ name: 'ASP.NET | .NET', level: 4 },
			{ name: 'Scrum', level: 4 },
			{ name: 'Bootstrap', level: 4 },
			{ name: 'Cloudflare', level: 4 },
			{ name: 'Payment Card Reader', level: 4 },
			{ name: 'Sanoid | Syncoid', level: 4 },
			{ name: 'Microsoft Graph', level: 4 },
			{ name: 'ZFS', level: 4 },
			{ name: 'Time Zone Management', level: 4 },
			{ name: 'Expo', level: 4 },

			{ name: 'USB', level: 3 },
			{ name: 'Udev Rules', level: 3 },
			{ name: 'Bluetooth', level: 3 },
			{ name: 'Swish', level: 3 },

			{ name: 'GTK3', level: 2 },
			{ name: 'C', level: 2 }
		].sort((a, b) => b.level - a.level || a.name.localeCompare(b.name));
	}, []);

	function getSize() {
		let width = document.documentElement.clientWidth || document.body.clientWidth;
		let height = document.documentElement.clientHeight || document.body.clientHeight;
		const navBarHeight = 55;
		if (width > 1023)
			width = 1023;
		if (height > 575)
			height = 575;
		height -= navBarHeight;
		return [width, height];
	}

	useEffect(() => {
		const updateSize = () => {
			setSize(getSize());
		};
		
		window.addEventListener('resize', updateSize);
		return () => {
			window.removeEventListener('resize', updateSize);
		};
	}, [])

	let prevSkillLevel = skills[0].level;
	return (
		<div ref={elementRef} className={`${classOnScreen}`}>
			<div className={`d-flex ${size[0] < 714 ? 'flex-column' : 'flex-row'} w-100`}>
				<div className="w-100">
					<div className="header">About Emil Olovsson</div>
					<div className="text py-4">Emil Olovsson is a seasoned software developer with an impressive track record spanning over {Tools.numberToWord(Portfolio.yearsExperience)} years in the industry. His expertise lies in the realm of web-based e-commerce and POS-systems, showcasing a robust proficiency in full stack development. As a freelance IT expert, Emil has honed his skills, delivering top-notch solutions to various clients and projects.</div>
					<Link className="text" to="/contact">Contact Emil &#62;</Link>
				</div>
				<div className={`${size[0] < 714 ? 'py-4' : 'p-4 pe-0'}`}>
					<GalleryPictures
						highlight={true}
						images={[
							{
								imageWidth: 305,
								imageHeight: 354,
								imageSrc: require(`../../components/gallery/images/emil_olovsson_about.png`)
							}
						]}
					/>
				</div>
			</div>
			<div className="header pb-3">Skills Matrix</div>
			{skills.map((skill, index) => {
				const diff = prevSkillLevel !== skill.level;
				prevSkillLevel = skill.level;
				return (
					<div key={index}>
						{diff && <div className="py-3"></div>}
						<Skill name={skill.name} level={skill.level} />
					</div>
				);
			})}
		</div>
	);
}
