import { useEffect, useState, useRef } from 'react';
import './useOnScreen.css';

export function useOnScreen(ref) {
	const [classOnScreen, setClassOnScreen] = useState('fade-in-section');
	const observerRef = useRef(null);

	useEffect(() => {
		observerRef.current = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting)
				setClassOnScreen('fade-in-section is-visible');
			else
				setClassOnScreen('fade-in-section');
		});
	}, []);

	useEffect(() => {
		observerRef.current.observe(ref.current);

		return () => {
			observerRef.current.disconnect();
		};
	}, [ref]);

	return classOnScreen;
}