import React from 'react';
import { useState, useRef, useEffect } from 'react';
import './Mousey.css';

export function Mousey() {
	const mouseyRef = useRef(null);
	const [opacity, setOpacity] = useState(1);
	const [scrollY, setScrollY] = useState(0);

	useEffect(() => {
		const handleWindowScroll = () => {
			let currScrollY = window.scrollY,
				maxScrollY = (document.documentElement.clientHeight || document.body.clientHeight) / 2;

			if (currScrollY > maxScrollY)
				currScrollY = maxScrollY;

			setScrollY(currScrollY);
			setOpacity(1 - (currScrollY / maxScrollY));
		};

		window.addEventListener('scroll', handleWindowScroll, false);
		window.addEventListener('resize', handleWindowScroll, false);
		handleWindowScroll();
		return () => {
			window.removeEventListener('scroll', handleWindowScroll, false);
			window.removeEventListener('resize', handleWindowScroll, false);
		};
	}, [mouseyRef, scrollY, opacity]);

	return (
		<>
			<div
				ref={mouseyRef}
				className="d-flex justify-content-center py-3 mt-auto"
				style={{
					opacity: opacity
				}}
			>
				<div className="mousey">
					<div className="scroller"></div>
				</div>
			</div>
		</>
	);
}
