import React from 'react';
import { ExpertisePart } from './ExpertisePart';

export function Expertise() {

	return (
		<>
			<ExpertisePart
				expertise={{
					header: {
						title: 'Application Developer',
						gradient: true,
						extrapaddingtop: false,
						descriptions: [
							'Our specialization focuses on the ways in which digital services can offer value for both user and business. By utilising the entire Software Development Life Cycle (SDLC) we can create valuable mobile apps, web applications, and software.'
						]
					}
				}}
			/>
			<ExpertisePart
				expertise={{
					header: {
						title: 'Conceptualization',
						descriptions: [
							'This is where the magic begins. We delve deep into your vision, understanding your goals, objectives, and the unique challenges you wish to overcome.'
						]
					},
					services: {
						descriptions: [
							'Brainstorming Sessions',
							'Idea Validation',
							'Initial Scope Definition'
						]
					}
				}}
			/>
			<ExpertisePart
				expertise={{
					header: {
						title: 'Planning & Feasibility',
						descriptions: [
							'We gather and document your requirements. This ensures that every detail is accounted for and aligns perfectly with your vision. We assess the technical, operational, and financial aspects to ensure your project is viable and sustainable.'
						]
					},
					services: {
						descriptions: [
							'Requirement Gathering',
							'Use Case Development',
							'Technical Assessment',
							'Operational Assessment',
							'Financial Assessment'
						]
					}
				}}
			/>
			<ExpertisePart
				expertise={{
					header: {
						title: 'Design & Architecture',
						descriptions: [
							'Our architects craft a blueprint for your software, creating a solid foundation that ensures scalability and performance. Our designers bring aesthetics and functionality together, ensuring an engaging and intuitive user experience.'
						]
					},
					services: {
						descriptions: [
							'Architectural Design',
							'Technology Stack Selection',
							'UI/UX Design',
							'Prototyping'
						]
					}
				}}
			/>
			<ExpertisePart
				expertise={{
					header: {
						title: 'Development',
						descriptions: [
							'Our talented developers breathe life into your project, writing clean, efficient, and maintainable code. Rigorous testing ensures that every feature meets the highest quality standards.'
						]
					},
					services: {
						descriptions: [
							'Writing Code',
							'Reviewing Code',
							'Testing Code',
							'Bug Fixing'
						]
					}
				}}
			/>
			<ExpertisePart
				expertise={{
					header: {
						title: 'Testing & Quality Assurance',
						descriptions: [
							'We rigorously test every feature to ensure they perform flawlessly. Your software is stress-tested to guarantee optimal speed and responsiveness. We fortify your software against potential threats and vulnerabilities.'
						]
					},
					services: {
						descriptions: [
							'Test Case Design',
							'Test Case Execution',
							'Stress Testing',
							'Performance Optimization',
							'Vulnerability Assessment',
							'Security Patching'
						]
					}
				}}
			/>
			<ExpertisePart
				expertise={{
					header: {
						title: 'Deployment & Release',
						descriptions: [
							'We carefully plan and execute the release of your software, ensuring a seamless transition from development to production. We provide training and support to ensure your team and users can make the most of your new software.'
						]
					},
					services: {
						descriptions: [
							'Deployment Planning',
							'Environment Setup',
							'Production Rollout',
							'User training'
						]
					}
				}}
			/>
			<ExpertisePart
				expertise={{
					header: {
						title: 'Maintenance & Support',
						descriptions: [
							'We monitor your software\'s performance and proactively address any issues or enhancements. Our dedicated support team is always ready to assist you.'
						]
					},
					services: {
						descriptions: [
							'Bug Fixes',
							'Feature Enhancements',
							'Performance Tuning',
							'24/7 Technical Support'
						]
					}
				}}
			/>
			<ExpertisePart
				expertise={{
					header: {
						title: 'Documentation',
						descriptions: [
							'We provide detailed documentation to facilitate future updates and maintenance.'
						]
					},
					services: {
						descriptions: [
							'User Manuals',
							'Technical Documentation',
							'API documentation'
						]
					}
				}}
			/>
			<ExpertisePart
				expertise={{
					header: {
						title: 'Project Closure',
						descriptions: [
							'We conduct a thorough review to ensure that every aspect of your project has been successfully addressed. We hand over all project assets, including code and documentation.'
						]
					},
					services: {
						descriptions: [
							'Project Review',
							'Requirement Verification',
							'Transfer of Project Assets'
						]
					}
				}}
			/>
			<ExpertisePart
				expertise={{
					header: {
						title: 'Post-Implementation Evaluation',
						descriptions: [
							'We actively seek feedback from users to refine and enhance your software further.'
						]
					},
					services: {
						descriptions: [
							'User Interviews',
							'Feedback Analysis'
						]
					}
				}}
			/>
			<ExpertisePart
				expertise={{
					header: {
						title: 'Conclusion',
						descriptions: [
							'Each of these services are meticulously executed to ensure the successful development, deployment, and maintenance of your software project. They are designed to align with the specific needs and goals of your project, resulting in a software solution that not only meets but exceeds your expectations.'
						]
					}
				}}
			/>
		</>
	);
}
