import React from 'react';
import { useRef, useEffect, useMemo } from 'react';
import './CanvasStar.css';

export function CanvasStar() {
	const canvasRef = useRef(null);
	const qualityMult = 2; // !!! OBS !!! OM DU �NDRAR DETTA V�RDE M�STE DU �NDRA SCALE F�R REGEL #canvas_star
	const STAR_NUM = 300 * qualityMult; // number of stars in the starfield
	let biggesSize = window.screen.availWidth > window.screen.availHeight ? window.screen.availWidth : window.screen.availHeight;
	biggesSize += 200; // Add extra size so phones with big notch like *cough* iPhone
	const MAX_CANVAS_HEIGHT = biggesSize * qualityMult;
	const MAX_CANVAS_WIDTH = biggesSize * qualityMult;

	const COLOR_SPACE = "#040615";
	const COLOR_STARS = "#fff";

	// set up the stars
	const stars = useMemo(() => {
		const STAR_SIZE = 0.001; // max star size as a fraction of screen width
		const MIN_STAR_SIZE = 0.4;
		const STAR_SPEED = 0.004; // fraction of screen width per second
		let starSpeed = STAR_SPEED * MAX_CANVAS_WIDTH;
		//let xv = starSpeed * randomSign() * Math.random();
		//let yv = Math.sqrt(Math.pow(starSpeed, 2) - Math.pow(xv, 2)) * randomSign();
		let stars = [];
		for (let i = 0; i < STAR_NUM; i++) {
			let speedMult = Math.random() * 1.5 + 0.1;
			stars[i] = {
				r: Math.max(Math.random() * STAR_SIZE * MAX_CANVAS_WIDTH / 2, MIN_STAR_SIZE), // Size
				x: Math.floor(Math.random() * MAX_CANVAS_WIDTH), // Start Position X
				y: Math.floor(Math.random() * MAX_CANVAS_HEIGHT), // Start Position Y
				xv: starSpeed * speedMult, // Velocity
				yv: starSpeed * speedMult // Velocity
				//xv: xv * speedMult, // Velocity
				//yv: yv * speedMult // Velocity
			}
		}
		return stars;
	}, []);

	useEffect(() => {

		const canvas = canvasRef.current;

		//const randomSign = () => Math.random() >= 0.5 ? 1 : -1;
		const ctx = canvas.getContext("2d");
		canvas.height = MAX_CANVAS_HEIGHT;
		canvas.width = MAX_CANVAS_WIDTH;

		// set up the animation loop
		let animationId, timeLast = Date.now();

		function loop() {
			let timeNow = Date.now();
			let timeDelta = timeNow - timeLast; // calculate the time difference
			timeLast = timeNow;

			// space background
			ctx.fillStyle = COLOR_SPACE;
			ctx.fillRect(0, 0, MAX_CANVAS_WIDTH, MAX_CANVAS_HEIGHT);

			// draw the stars
			ctx.fillStyle = COLOR_STARS;
			for (let i = 0, star; i < STAR_NUM; i++) {
				star = stars[i];

				ctx.beginPath();
				ctx.arc(star.x, star.y, star.r, 0, Math.PI * 2);
				ctx.fill();

				// update the star's x position
				star.x += star.xv * timeDelta * 0.001;

				if (star.x < 0 - star.r)
					star.x = MAX_CANVAS_WIDTH + star.r;
				// reposition the star to the other side if it goes off screen
				else if (star.x > MAX_CANVAS_WIDTH + star.r)
					star.x = Math.floor(
						star.x % MAX_CANVAS_WIDTH // Calculate position where star should be next frame
						- (0 - star.r) // Subtract so the star always starts outside the viewport
					);

				/*
				// update the star's y position
				star.y += star.yv * timeDelta * 0.001;

				if (star.y < 0 - star.r)
					star.y = MAX_CANVAS_HEIGHT + star.r;
				// reposition the star to the other side if it goes off screen
				else if (star.y > MAX_CANVAS_HEIGHT + star.r)
					star.y = 0 - star.r;
				*/
			}

			// call the next frame
			animationId = requestAnimationFrame(loop);
		}

		loop();
		return () => {
			cancelAnimationFrame(animationId);
		};
	}, [canvasRef])

	return (
		<canvas id="canvas_star" ref={canvasRef} />
	);
}